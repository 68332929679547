import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { useSession, signIn } from "next-auth/react";
import axios from 'axios';
import { useUserStore } from '@/stores/useUserStore';
import { User } from '@/stores/initialState';
import Link from 'next/link';
import AlertDialog from '../Utils/AlertDialog';
import { motion } from 'framer-motion';
import { motionVariants } from '../Common/GlobalVars';
import { bindVoucher } from '@/lib/Definitions';
import CryptoJS from 'crypto-js';
import { event } from 'nextjs-google-analytics';
import { isValidPhoneNumber } from 'libphonenumber-js';


const Login = () => {
  const router = useRouter();
  const { sbind, redirect } = router.query;
  const { userInfo, getUser, updateUser } = useUserStore();
  const { data, status } = useSession();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [userData, setUserData] = useState(getUser());
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    setUserData(getUser());
  }, [getUser, userInfo])

  useEffect(() => {
    const checkInput = async () => {
      if (email === "" || email.length === 0) {
        setMessage("Please enter your email or phone number");
      } else if (!emailRegex.test(email) && !isValidPhoneNumber(email, "ID")) {
        setMessage("Please enter a valid email or phone number");
      } else if (password === "" || password.length === 0) {
        setMessage("Please enter your password");
      } else {
        await processLogin();
      }
    }

    const processLogin = async () => {
      handleLogin()
        .then((response) => {
          if (response !== undefined && response.error === undefined) {
            handleUpdateUser(response)
              .then(() => {
                handleRedirect()
              })
          }
        })
        .catch((error) => {
          switch (error.response.data.error) {
            case "Wrong password":
              setMessage("Wrong password");
              break;

            case "cannot found this customer":
              setMessage("The account for this email cannot be found");
              break;

            default:
              setMessage("here was an error while process login, please refresh this page and try again.");
          }
        });
    }

    const handleLogin = async () => {
      let userEmail = null;
      let userPhone = null;

      if (emailRegex.test(email)) {
        userEmail = email;
      } else if (isValidPhoneNumber(email, "ID")) {
        userPhone = email;
      }

      let body = {
        "username": userData.uuid,
        "email": userEmail,
        "mobile": userPhone,
        "password": password,
        "logintype": 'email',
        "social_network_id": "",
        "access_token": "",
        "name": "",
        "action": "login",
        "timestamp": new Date().getTime(),
      }

      if (status === 'authenticated') {
        body.logintype = data.provider;
        body.email = data.user.email;
        body.social_network_id = data.user.id;
      }

      try {
        const response = await axios.post("/api/auth", {data: CryptoJS.AES.encrypt(JSON.stringify(body), process.env.NEXT_PUBLIC_KEY || '').toString()});

        if (response.data.error) {
          switch (response.data.error) {
            case "Wrong password":
              setMessage("Wrong password");
              break;

            case "cannot found this customer":
              setMessage("The account for this email cannot be found");
              break;

            case "user not exists":
              setMessage("The account for this email cannot be found");
              break;

            default:
              try {
                const data = {data: {email: body.email, mobile: body.mobile, logintype: body.logintype, error: response.data.error, user_id: userData.user_id, uid: userData.uuid, type: "normal login", path: 'login'}, timestamp: new Date().getTime()};
                await axios.post(
                  "/api/report",
                  {data: CryptoJS.AES.encrypt(JSON.stringify(data), process.env.NEXT_PUBLIC_KEY || '').toString()}
                )
              } catch (error) { }
              setMessage("here was an error while process login, please refresh this page and try again.");
          }
        } else {
          event("login", {
            method: body.logintype,
            user_id: response.data.user_id,
          })

          try {
            window.fbq('track', 'Login', {
              content_name: "User Login",
              value: 0,
              currency: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'IDR',
            })
          } catch (error) { }

          try {
            window.gtag('event', 'login', {
              method: body.logintype,
              user_id: response.data.user_id,
            })
          } catch (error) { }

          return response.data;
        }
      } catch (error: any) {
        if (error?.response?.data.error === "user not exists" && status === 'authenticated') {
          body.action = "register";
          body.username = userData.uuid;
          body.logintype = data.provider;
          body.email = data.user.email;
          body.social_network_id = data.user.id;
          body.name = data.user.name;
          body.timestamp = new Date().getTime();

          try {
            const response = await axios.post("/api/auth", {data: CryptoJS.AES.encrypt(JSON.stringify(body), process.env.NEXT_PUBLIC_KEY || '').toString()});

            if (response.data.error) {
              try {
                const data = {data: {email: body.email, mobile: body.mobile, logintype: body.logintype, error: response.data.error, user_id: userData.user_id, uid: userData.uuid, type: "auto register", path: 'login'}, timestamp: new Date().getTime()};
                await axios.post(
                  "/api/report",
                  {data: CryptoJS.AES.encrypt(JSON.stringify(data), process.env.NEXT_PUBLIC_KEY || '').toString()}
                )
              } catch (error) { }
              setMessage("here was an error while process login, please refresh this page and try again.");
            } else {
              event("sign_up", {
                method: body.logintype,
                user_id: response.data.user_id,
              })

              try {
                window.fbq('track', 'CompleteRegistration', {
                  content_name: "User Register",
                  value: 0,
                  currency: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'IDR',
                })
              } catch (error) { }

              try {
                window.gtag('event', 'sign_up', {
                  method: body.logintype,
                  user_id: response.data.user_id,
                })
              } catch (error) { }

              return response.data;
            }
          } catch (error) {
            try {
              const data = {data: {email: body.email, mobile: body.mobile, logintype: body.logintype, error: error, user_id: userData.user_id, uid: userData.uuid, type: "register", path: 'login'}, timestamp: new Date().getTime()};
              await axios.post(
                "/api/report",
                {data: CryptoJS.AES.encrypt(JSON.stringify(data), process.env.NEXT_PUBLIC_KEY || '').toString()}
              )
            } catch (error) { }
            setMessage("The account for this email cannot be found");
          }
        } else {
          switch (error?.response?.data.error) {
            case "Wrong password":
              setMessage("Wrong password");
              break;

            case "cannot found this customer":
              setMessage("The account for this email cannot be found");
              break;

            default:
              try {
                const data = {data: {email: body.email, mobile: body.mobile, logintype: body.logintype, error: error?.response?.data.error || error, user_id: userData.user_id, uid: userData.uuid, type: "login", path: 'login'}, timestamp: new Date().getTime()};
                await axios.post(
                  "/api/report",
                  {data: CryptoJS.AES.encrypt(JSON.stringify(data), process.env.NEXT_PUBLIC_KEY || '').toString()}
                )
              } catch (error) { }
              setMessage("here was an error while process login, please refresh this page and try again.");
          }
        }
      }
    }

    const handleUpdateUser = async (response: any) => {
      const user: User = {
        user_id: response.user_id,
        email: response.email,
        membershiplevel: response.membership_level,
        token: response.token,
        barcode: '',
        mooimom_point: 0,
        name: userData.name !== null && userData.name.length > 0 ? userData.name : response.name,
        phone: '',
        referral_code: '',
        uuid: userData.uuid,
        refresh_token: response.refresh_token,
        country_code: '',
        birthday: null,
        gender: '',
        mooimom_point_deactivate_date: null,
        order_status: {
          cancel: 0,
          cancel_shipping: 0,
          completed: 0,
          processing: 0,
          reviewed: 0,
          draft: 0,
          paid: 0,
          delivered: 0,
          return: 0
        },
        married: false,
        login_type: response.login_type,
        update_date: Date.now(),
      }

      await handleBindVoucher(user);

      updateUser(user);
      setUserData(user);
    }

    const handleBindVoucher = async (user: User) => {
      if (sbind !== undefined) {
        const str = atob(sbind as string);

        if (str.slice(0, 2) === "vc") {
          const vouchers = str.slice(2).split(",");

          bindVoucher('Bearer ' + user.token, {voucher_list: vouchers})
            .then((response) => {

            })
        }
      }
    }

    const handleRedirect = () => {
      if (redirect !== undefined) {
        if (typeof(redirect) === 'string') {
          if (redirect.indexOf("affiliate.mooimom.id") > -1) {
            router.push(redirect + "?uid=" + userData.token);
          } else {
            router.push(redirect);
          }
        } else {
          router.push(redirect[0]);
        }
      } else {
        router.push('/');
      }
    }

    if (status === 'authenticated') {
      processLogin();
    }

    if (userData.login_type !== "guest" && userData.membershiplevel !== "none" && userData.membershiplevel !== "" && userData.user_id !== undefined) {
      handleBindVoucher(userData)
        .then((response) => {
          handleRedirect();
        })
    }

    const loginBtn = document.getElementById("loginBtn");

    if (loginBtn !== null) {
      loginBtn.addEventListener("click", checkInput);

      return () => {
        loginBtn.removeEventListener('click', checkInput);
      };
    }
  }, [data, email, password, redirect, router, sbind, status, updateUser, userData.email, userData.membershiplevel, userData.name, userData.user_id, userData.uuid])

  useEffect(() => {
    if (message !== "") {
      setOpen(true);
    }
  }, [message]);

  useEffect(() => {
    if (!open) {
      setMessage("");
    }
  }, [open]);

  return (
    <div className="w-full grid gird-cols-1 md:grid-cols-2 gap-12 md:items-start mb-[69px] md:mb-0">
      <motion.div
        className="hidden w-full md:block"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
        variants={motionVariants}
      >
        <Image
          src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/login-desktop.jpg"}
          alt="login image"
          width={900}
          height={1190}
          quality={100}
        />
      </motion.div>
      <motion.div
        className="w-full md:hidden"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
        variants={motionVariants}
      >
        <Image
          src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/login-mobile.jpg"}
          alt="login image"
          width={600}
          height={400}
          quality={100}
        />
      </motion.div>
      <motion.div
        className="mx-[35px] md:mx-auto md:w-[500px] font-poppins"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
        variants={motionVariants}
      >
        <div className="justify-center hidden md:flex md:mt-20">
          <Image
            src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/logo.png"}
            alt="logo"
            quality={100}
            width={500}
            height={80}
            className="w-[250px] h-auto"
          />
        </div>
        <hr className="w-full bg-reviewBg my-12 hidden md:block" />
        <h1 className="text-[24px] md:text-[32px] font-bold text-mooimom text-left w-full">Login</h1>
        <input
          type="text"
          name="email"
          value={email}
          placeholder="Email / Phone Number"
          className="w-full px-4 py-2 text-xs border rounded-lg outline-none mt-7 placeholder:text-sku border-sku focus:ring-transparent focus:border-sku"
          onChange={event => setEmail(event.target.value)}
        />
        <div className="relative w-full mt-7">
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            value={password}
            placeholder="Password"
            className="w-full px-4 py-2 text-xs border rounded-lg outline-none placeholder:text-sku border-sku focus:ring-transparent focus:border-sku"
            onChange={event => setPassword(event.target.value)}
          />
          <Image
            src={showPassword ? process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/eye.svg" : process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/eye-slash.svg"}
            alt="eye icon"
            width={20}
            height={20}
            quality={100}
            className="absolute -translate-y-1/2 cursor-pointer right-5 top-1/2"
            onClick={() => setShowPassword(!showPassword)}
          />
        </div>
        <p className="text-xs font-medium text-left underline text-mooimom mt-7">
          <Link href="/password">
            Lupa Password ?
          </Link>
        </p>
        <button
          className="w-full px-12 py-2 text-base font-medium text-center text-white rounded-lg bg-mooimom mt-7"
          id="loginBtn"
        >
          Login
        </button>
        <div className="flex justify-start text-xs font-medium text-black mt-7">
          <p>Tidak mempunyai akun? Silahkan&nbsp;</p>
          <Link href="/signup">
            <p className="underline text-mooimom">Daftar</p>
          </Link>
        </div>
        <div className="flex items-center justify-center w-full my-6 space-x-4">
          <hr className="w-full bg-reviewBg"></hr>
          <p className="mx-4 text-sm font-normal text-black capitalize">
            Atau
          </p>
          <hr className="w-full bg-reviewBg"></hr>
        </div>
        <div className="grid grid-cols-2 gap-x-4">
          <button
            className="flex items-center justify-center px-4 py-2 space-x-4 border rounded-lg border-sku"
            onClick={() => signIn("google")}
          >
            <p className="text-xs font-medium text-brandBorder font-poppins">Google</p>
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/google-icon.png"}
              alt="google icon"
              quality={100}
              width={20}
              height={20}
            />
          </button>
          <button
            className="flex items-center justify-center px-4 py-2 space-x-4 border rounded-lg border-sku"
            onClick={() => signIn("facebook")}
          >
            <p className="text-xs font-medium text-brandBorder font-poppins">Facebook</p>
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/fb-icon.png"}
              alt="facebook icon"
              quality={100}
              width={20}
              height={20}
            />
          </button>
        </div>
      </motion.div>
      <AlertDialog message={message} open={open} setOpen={setOpen} />
    </div>
  )
}

export default Login
